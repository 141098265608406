<template>
  <div class="">
    <div class="bg-white my-6">
      <table class="text-left w-full border-collapse">
        <tbody>
          <tr
            v-for="(item, index) in tableData"
            :key="index"
            class="hover:bg-grey-lighter"
            :class="index % 2 === 0 && 'bg-gray-100'"
          >
            <td v-for="(sub, i) in item" :key="sub" class="p-4 border-b border-grey-light text-base text-left">
              {{ sub }}
            </td>
            <!-- <td class="py-4 px-6 border-b border-grey-light text-base">{{item.normal_attendees}}</td>
              <td class="py-4 px-6 border-b border-grey-light text-base">{{item.students}}</td> -->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      // 基础信息
      this.$http.get(`/index/getexcel`).then((res) => {
        console.log(res);
        this.tableData = res.data.data;
      });
    },
  },
};
</script>
